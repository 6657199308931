import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AlertText from 'components/AlertText';
import {
  MineModelDto,
  ProjectDto,
  RheologyDataDto,
  StopeBackfillSummaryDto,
  StopeLocationDto
} from 'providers/api';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { MineModelApi } from '../canvas/cmodel';
import PoursTabPanel from './PoursTab/PoursTabPanel';
import RecipesTabPanel from './RecipesTab/RecipesTabPanel';
import StopeTitleCard from './StopeTitleCard';

interface StopePanelPageProps {
  project: ProjectDto;
  mineModel: MineModelDto;
  mineOverview: StopeBackfillSummaryDto[];
  mineModelApi: MineModelApi | null;
  selectedRouteSpoolIds: string[];
  initialRheologyDataset?: RheologyDataDto;
  onStopeEdit: (nodeId: string) => void;
}

type StopePanelTab = 'recipes' | 'pours'

const StopePanelPage = ({
  project,
  mineModel,
  mineOverview,
  mineModelApi,
  selectedRouteSpoolIds,
  initialRheologyDataset,
  onStopeEdit,
}: StopePanelPageProps) => {
  const params = useParams<{ stopeId: StopeLocationDto['stopeId'], tab: string }>();
  const history = useHistory();
  const location = useLocation();
  const [tabValue, setTabValue] = React.useState<StopePanelTab>(params.tab as StopePanelTab ?? 'recipes');
  const [stopeSelected, setStopeSelected] = React.useState(false);
  const stope = mineModel.reticulationData.stopeLocations.find((n) => n.stopeId === params.stopeId);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: StopePanelTab) => {
    setTabValue(newValue);
    history.replace(location.pathname.replace(/[^/]*$/, newValue));
  };

  if (!stope) {
    throw new Error('Selected stope not found in reticulation data');
  }

  const stopeOverview = mineOverview.find((stopeSummary) => stopeSummary.stopeIdentifier.stopeId === stope.stopeId);

  const stopeData = mineModel.reticulationData.stopes.find((data) => data.stopeId === stope.stopeId);

  // Manage stope selection and deselection on component mount and unmount
  React.useEffect(() => {
    if (mineModelApi && stope && !stopeSelected) {
      mineModelApi && mineModelApi.triggerSelectStope(stope.nodeId);
      setStopeSelected(true);
    }
    return () => {
      mineModelApi && mineModelApi.triggerUnselectStope();
      setStopeSelected(false);
    };
  }, [mineModelApi]);

  return (
    <>
      <Box>
        <StopeTitleCard
          stopeLocation={stope}
          stopeName={stopeData?.stopeName ?? ''}
          hasIncompletePours={!!stopeOverview?.nextPourDate}
          stopeComplete={!!stopeOverview?.isComplete}
          completedVolume={stopeOverview?.completedVolume ?? 0} // if there is no stopeBackfillData progress is 0
          plannedVolume={stopeOverview?.plannedVolume ?? 0}
          volume={stopeData?.volume}
          onStopeEdit={onStopeEdit}
          onDeleteSuccess={mineModelApi?.deleteStope}
          unitSystemPreference={project.unitSystemPreference}
        />
      </Box>

      {!stopeData && <AlertText severity="info">No data has been uploaded for this stope</AlertText>}

      {stopeData && (
        <>
          <Box py={2}>
            <Paper square>
              <Tabs
                variant="fullWidth"
                value={tabValue}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="stope menu"
              >
                <Tab value="recipes" label="Recipes" />
                <Tab value="pours" label="Pours" />
              </Tabs>
            </Paper>
          </Box>
          {
            tabValue === 'pours' ? (
              <PoursTabPanel
                projectId={project.entityId}
                stopeId={stope.stopeId}
                displayUnitPreferences={project.displayUnitPreferences ?? []}
                unitSystemPreference={project.unitSystemPreference}
                fillType={project.fillType}
                throughputControlType={project.throughput}
              />
            ) : (
              <>
                <RecipesTabPanel
                  selectedRouteSpoolIds={selectedRouteSpoolIds}
                  mineModelApi={mineModelApi}
                  projectId={project.entityId}
                  rheologyDatasetList={mineModel.rheologyDataList ?? []}
                  displayUnitPreferences={project.displayUnitPreferences ?? []}
                  fillType={project.fillType}
                  throughputControlType={project.throughput}
                  unitSystemPreference={project.unitSystemPreference}
                  stopeId={stope.stopeId}
                  stopeName={stopeData?.stopeName ?? ''}
                  stopeComplete={stopeOverview?.isComplete ?? false}
                  initialRheologyDataset={initialRheologyDataset}
                />
              </>
            )
          }
        </>
      )}
    </>
  );
};

export default StopePanelPage;
