import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';
import { FormikHelpers } from 'formik';
import { throughputUnitLabelFromThroughputControlType } from 'hooks/useDisplayUnits/display';
import { Form } from 'hooks/useForm';
import { BackfillRecipeForm, ThroughputControlType, UnitSystem } from 'providers/api';
import { round } from 'utils';
import { BINDER_TAILINGS_MARKS } from 'utils/backfill';
import { DictionaryRange } from '../../../canvas/typesInterfaces';
import UnitSystemNumberField from './UnitSystemNumberField';
import UnitSystemNumberSlider from './UnitSystemNumberSlider';

interface HydraulicTargetInputsProps {
  isOptimised: boolean;
  values: BackfillRecipeForm;
  limits: DictionaryRange;
  isSubmitting: boolean;
  isValid: boolean;
  unitSystemPreference: UnitSystem;
  throughputControlType: ThroughputControlType;
  handleBlur: (e: any) => void | void;
  setFieldValue: FormikHelpers<BackfillRecipeForm>['setFieldValue'];
  onTargetsUpdate: () => void;
  onReset: () => void;
  hasError: Form['helpers']['hasError'];
  getErrorHelpText: Form['helpers']['getErrorHelpText'];
}

const HydraulicTargetInputs = ({
  isOptimised,
  values,
  limits,
  isSubmitting,
  isValid,
  unitSystemPreference,
  throughputControlType,
  handleBlur,
  setFieldValue,
  onTargetsUpdate,
  onReset,
  hasError,
  getErrorHelpText,
}: HydraulicTargetInputsProps) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <Typography variant="caption">Hydraulic Fill</Typography>
    </Grid>
    <Grid item xs={12}>
      <Box>
        <FormHelperText id="recipe-reference-helper-text" component="label">Recipe Reference</FormHelperText>
        <TextField
          size="small"
          error={hasError('reference')}
          helperText={getErrorHelpText('reference')}
          required
          autoFocus
          fullWidth
          id="reference"
          name="reference"
          aria-labelledby="recipe-reference-helper-text"
          value={values.reference}
          disabled={isOptimised}
          onChange={(e) => setFieldValue('reference', e.target.value)}
          onBlur={handleBlur}
        />
      </Box>
    </Grid>

    {/* Required fields */}

    <Grid item xs={4}>
      <FormHelperText id="tonnage-helper-text" component="label">
        { throughputUnitLabelFromThroughputControlType(throughputControlType).name }
      </FormHelperText>
      <UnitSystemNumberField
        unitSystem={unitSystemPreference}
        unitInfo={throughputUnitLabelFromThroughputControlType(throughputControlType)}
        disabled
        size="small"
        decimalPlaces={0}
        id="tonnage-input"
        name="tonnage-input"
        aria-labelledby="tonnage-helper-text"
        value={values.hydraulicSpecification?.throughput ?? limits.tons.default ?? limits.tons.min}
        error={hasError('hydraulicSpecification.throughput')}
        helperText={getErrorHelpText('hydraulicSpecification.throughput')}
        onBlur={handleBlur}
        InputProps={{
          endAdornment: <InputAdornment position="end">t/h</InputAdornment>,
        }}
      />

    </Grid>
    <Grid item xs={8}>
      <Box pt={2} mx={1} px={1}>
        <UnitSystemNumberSlider
          unitSystem={unitSystemPreference}
          unitInfo={throughputUnitLabelFromThroughputControlType(throughputControlType)}
          value={values.hydraulicSpecification?.throughput ?? limits.tons.default}
          decimalPlaces={0}
          min={limits.tons.min}
          step={
            unitSystemPreference === UnitSystem.Metric
              ? limits.tons.interval
              : limits.tons.interval
          }
          max={limits.tons.max}
          onSliderChange={(event: any, newValue: number | number[]) => setFieldValue('hydraulicSpecification.throughput', parseFloat((newValue as number).toFixed(2)))}
          valueLabelDisplay="auto"
          disabled={isOptimised}
          aria-labelledby="tonnage-helper-text"
          // TODO: Put the marks back into the slider (Alcwyn)
          // marks={
          //   unitSystemPreference === UnitSystem.Metric
          //     ? limits.tons.data.map((value: number) => ({ value }))
          //     : limits.tons.data.map((value: number) => ({ value: unitLabels[BasicDisplayUnits.Tonnage].imperial.conversion(value) }))
          // }
        />
      </Box>
    </Grid>

    <Grid item xs={4}>
      <FormHelperText id="adjusted-concentration-helper-text" component="label">Binder:Tailings</FormHelperText>
      <TextField
        size="small"
        // type="number"
        disabled
        fullWidth
        id="adjusted-concentration-input"
        name="adjusted-concentration-input"
        value={`1:${round(100 / ((values.hydraulicSpecification?.binderTailingsRatio ?? 0.02) * 100), 0)}`}
        aria-labelledby="adjusted-concentration-helper-text"
      />
    </Grid>

    <Grid item xs={8}>
      <Box mx={1} px={1}>
        <Slider
          value={values.hydraulicSpecification?.binderTailingsRatio ?? 0.02}
          min={0.02}
          step={null}
          max={0.1}
          marks={BINDER_TAILINGS_MARKS}
          onChange={(e, n) => setFieldValue('hydraulicSpecification.binderTailingsRatio', n as number)}
          aria-labelledby="adjusted-concentration-helper-text"
          disabled={isOptimised}
        />
      </Box>
    </Grid>

    <Grid item xs={12}>
      <Box pt={1} pb={1}>
        <Button
          fullWidth
          aria-label="add new recipe"
          variant="contained"
          color="primary"
          size="large"
          disabled={isSubmitting || !isValid}
          onClick={(e) => {
            e.preventDefault();
            isOptimised ? onReset() : onTargetsUpdate();
          }}
        >
          {isOptimised ? 'Re-optimise' : 'Optimise'}
        </Button>
      </Box>
    </Grid>
  </Grid>
);

export default HydraulicTargetInputs;
